(function (){
  const configPath = process.env.REACT_APP_APP_HOST
  const thisScript = document.querySelector(`script[src^="${configPath}chatpoint"]`)
  const baseUrl = process.env.REACT_APP_API_HOST + 'public'
  if (!thisScript) return
  const websiteId = thisScript.dataset.websiteId || new URL(thisScript?.src).searchParams.get('websiteId')
  if (!websiteId) return;
  fetch(configPath + 'chatpoint_config.json', {headers: {'Cache-Control': 'no-cache'}}).then(res => res.json()).then((config) => {
    fetch(`${baseUrl}/websites/${websiteId}`).then(res => res.json()).then(res => {
      res.forEach(({type, id}) => {
        const script = document.createElement('script')
        script.setAttribute('async', 'async')
        script.setAttribute('src', `${configPath}widgets/${config[type]}.js`)
        script.setAttribute('data-id', `chatpoint-${type}`)
        script.setAttribute('data-website-id', websiteId)
        script.setAttribute('data-target-id', id)
        if (document.body) {
                  document.body.appendChild(script)
                } else {
                  window.addEventListener('DOMContentLoaded',()=>document.body.appendChild(script))
                }
            })
        })
    })
})()